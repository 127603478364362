import { Component } from 'react';
import './App.css';
import './css/reset.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './components/About';
import Background from './components/Bg';

class App extends Component {
    render(){
        return (
            <div className="App">
                <Background />
                <Router>
                    <Switch>
                        <Route path='/' exact><About /></Route>
                        <Route path='/about' exact><About /></Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
