import { Component } from "react";
import style from '../css/about.module.scss';
import SplitTextJS from 'split-text-js';
import {TweenMax, Expo } from 'gsap';

class About extends Component {
    componentDidMount(){
        const splittedText = new SplitTextJS(this.about, {type: "words"});
        const words = splittedText.words;
        TweenMax.staggerFrom(words, .7, {alpha: 0, y: 5, ease: Expo.easeInOut, delay: 1}, .015);
        TweenMax.from(this.button, .4, {alpha: 0, ease: Expo.easeOut, delay: 2})
    }
    render(){
        return(
            <>
                <div className={style.container}>
                    <h1 className={style.about} ref={el => this.about = el}>
                        Toufic Nabi is a web designer and developer based in Toronto, Canada. Over the past 7 years he focused on Front-End Development that includes: UI/UX Design, HTML5, CSS3, Javascript, jQuery and React. With a touch of PHP and Wordpress, he explaored the CMS and back end Development as well.
                    </h1>
                    <a href="/ex/samplework.html" className={style.workButton} ref={el => this.button = el}>View Works</a>
                </div>
            </>
        );
    }
}

export default About;