import { Component } from 'react';
import style from '../css/bg.module.scss';
import { TweenMax, Expo } from 'gsap';
 
class Background extends Component {

    componentDidMount(){
        TweenMax.from('#bg-parts div', 2, {scaleX: 0, ease: Expo.easeInOut}, .1)
    }

    render(){
        return (
            <div className={style.bg_parts} id="bg-parts">
                <div className={style.bg_parts__chunk}></div>
                <div className={style.bg_parts__chunk}></div>
                <div className={style.bg_parts__chunk}></div>
                <div className={style.bg_parts__chunk}></div>
            </div>
        )
    }
}

export default Background;
